import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Roles = React.lazy(() => import('./views/Pages/Roles'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/roles" name="Roles" render={props => <Roles {...props}/>} />
             
              <Route path="/dashboard" name="Dashboard" render={props => <DefaultLayout {...props}/>} />
              {/* <Route path="/dashboard/users" name="Dashboard > Users" render={props => <DefaultLayout {...props}/>} /> */}
              <Route path="/dashboard/general" name="Dashboard > General" render={props => <DefaultLayout {...props}/>} />

              {/*Administrador*/ }
              <Route path="/users" name="Administrador" render={props => <DefaultLayout {...props}/>} />
              <Route path="/users/list" name="Administrador > Usuarios" render={props => <DefaultLayout {...props}/>} />
              <Route path="/users/new" name="Administrador > Nuevo Usuario" render={props => <DefaultLayout {...props}/>} />
              
              {/* Questions */}
              <Route path="/questions" name="Cuestionarios" render={props => <DefaultLayout {...props}/>} />
              <Route path="/questions/list" name="Cuestionarios > Lista" render={props => <DefaultLayout {...props}/>} />

              {/*Organizaciones*/}
              <Route path="/clients" name="Organizaciones" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/list" name="Organizaciones > Lista" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/new" name="Organizaciones > Agregar" render={props => <DefaultLayout {...props}/>} />
              {/* <Route path="/clients/detail" name="Organizaciones > Detalle" render={props => <DefaultLayout {...props}/>} /> */}
              <Route path="/clients/registers" name="Organizaciones > Detalle > Registros" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/covid0" name="Organizaciones > COVID > Contexto Organizacional > Resultados" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/covid0_results" name="Organizaciones > COVID > Contexto Organizacional > Resultados" render={props => <DefaultLayout {...props}/>} />
              
              
              {/* <Route path="/clients/compilance" name="Organizaciones > COVID > Instrumentos de Cumplimiento" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/compilance_general" name="Organizaciones > COVID > Identificación de Instrumentos de Cumplimiento" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/compilance_evaluation" name="Organizaciones > COVID > Instrumentos de Cumplimiento > Evaluación general" render={props => <DefaultLayout {...props}/>} /> */}
              
              <Route path="/clients/covid_instrument" name="Organizaciones > COVID > Instrumentos de Cumplimiento > Resumen Instrumento" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/covid_instrument_evaluation" name="Organizaciones > COVID > Instrumentos de Cumplimiento > Evaluación Instrumento" render={props => <DefaultLayout {...props}/>} />


              <Route path="/clients/covid_risk" name="Organizaciones > COVID > Identificación de Riesgos" render={props => <DefaultLayout {...props}/>} />
              <Route path="/clients/covid_risk_list" name="Organizaciones > COVID > Identificación de Riesgos > Riesgos" render={props => <DefaultLayout {...props}/>} />

              <Route path="/implantaciones" name="Implantaciones" render={props => <DefaultLayout {...props}/>} />
              <Route path="/implantaciones/list" name="Implantaciones > Lista" render={props => <DefaultLayout {...props}/>} />


              {/*Registros*/}
              <Route path="/gicl" name="Registros" render={props => <DefaultLayout {...props}/>} />
              <Route path="/gicl/list" name="Registros > Lista" render={props => <DefaultLayout {...props}/>} />
              <Route path="/gicl/new" name="Registros > Agregar" render={props => <DefaultLayout {...props}/>} />
              <Route path="/gicl/detail" name="Registros > Detalle" render={props => <DefaultLayout {...props}/>} />
              <Route path="/gicl/detail/edit" name="Registros > Detalle > Editar" render={props => <DefaultLayout {...props}/>} />

              {/*Registros*/}
              <Route path="/files" name="Archivos" render={props => <DefaultLayout {...props}/>} />
              <Route path="/files/request" name="Archivos > Solicitudes" render={props => <DefaultLayout {...props}/>} />

              {/* Informes */}
              <Route path="/reports" name="Reportes" render={props => <DefaultLayout {...props}/>} />

              {/* Agenda */}
              <Route path="/agenda" name="Agenda" render={props => <DefaultLayout {...props}/>} />

              {/* Implantation */}
              <Route path="/implantation" name="Implantación" render={props => <DefaultLayout {...props}/>} />

              {/* Revision */}
              <Route path="/revision" name="Revisión" render={props => <DefaultLayout {...props}/>} />

              {/* Notificaciones */}
              <Route path="/notifications/general" name="Notificaciones" render={props => <DefaultLayout {...props}/>} />
              <Route path="/notifications/detail" name="Notificaciones" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
